// Utils
import styled from "styled-components"
import { motion, Variants, Transition, HTMLMotionProps } from "framer-motion"

// Types
import { INotificationActionType } from "../../../context/notification/notification.types"
export interface IProps extends HTMLMotionProps<"div"> {
	action: INotificationActionType
	text?: string
}
interface IContainerProps {
	action: INotificationActionType
}

const NotificationItem: React.FC<IProps> = ({ action, text, ...props }) => {
	const containerVariants: Variants = {
		hidden: {
			opacity: 0,
			x: 300,
		},
		visible: {
			opacity: 1,
			x: 0,
		},
	}

	const containerTransition: Transition = {
		type: "spring",
		stiffness: 300,
		damping: 30,
	}

	return (
		<NotificationContainer
			variants={containerVariants}
			initial="hidden"
			animate="visible"
			exit="hidden"
			action={action}
			transition={containerTransition}
			{...props}
		>
			<Header action={action}>
				<h2>{action}</h2>
			</Header>
			<p dangerouslySetInnerHTML={{ __html: text || "" }}></p>
		</NotificationContainer>
	)
}

export default NotificationItem

const NotificationContainer = styled(motion.div)<IContainerProps>`
	width: calc(100% - 3rem);
	padding: 1.5rem;
	margin: 0 1.5rem;
	box-shadow: 0.3rem 0 1rem 0.5rem rgba(0, 0, 0, 0.05);
	background-color: ${({ theme }) => theme.background.primary};
	border-radius: 1.5rem;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	p {
		padding-left: 1rem;
		margin-top: 1rem;
		font-size: 1.3rem;
		font-weight: 400;
	}

	@media all and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		/* max-width: 40rem; */
	}
`

const Header = styled.div<IContainerProps>`
	display: flex;
	justify-content: space-between;
	align-items: center;

	h2 {
		color: ${({ action, theme }) =>
			action === "SUCCESS" || action === "ÚSPEŠNE"
				? theme.notification.successColor
				: action === "INFO"
				? theme.notification.infoColor
				: theme.notification.errorColor};
		padding-left: 1rem;
		font-size: 1.6rem;
	}
	border-bottom: 1px solid ${({ theme }) => theme.fonts.primary};
`
