import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: `${
    process.env.REACT_APP_BACKEND_URL ?? "http://localhost:3000"
  }/graphql`,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        process.env.REACT_APP_AT ??
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBRFZJU0lPTiI6e30sImlhdCI6MTY0MzM5ODk5NSwic3ViIjoiNjFmNDQ1ZmVhM2I1MmE5MTBjNjE4YTY5In0.L-3qlujxXZK25ZGjyr4BDF5ljkYhKrfUC8UjwFWPSrI"
      }`,
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  connectToDevTools: true,
});
