// Utils
import styled from "styled-components"

export interface IProps {
	children: React.ReactNode | React.ReactNode[]
}

const Wrapper: React.FC<IProps> = ({ children }) => {
	return (
		<WrapperStyles>
			<div />
			{children}
			<div id="wrapper-portal" />
		</WrapperStyles>
	)
}

export default Wrapper

const WrapperStyles = styled.div`
	/* max-width: 585px; */
	/* max-width: 60rem;
	width: 100%; */
	margin: 0 auto;
	padding: 5rem 1.5rem;
	display: grid;
	align-items: center;
	gap: 0.8rem;
	grid-template-columns: 1fr minmax(auto, 60rem) 1fr;
`
