import { gql } from "@apollo/client"

export const PUSH_PRICE_OFFER = gql`
	mutation PushPriceOffer(
		$id: String
		$calculator: String
		$params: CalculatedPriceOfferParams
	) {
		pushPriceOffer(id: $id, calculator: $calculator, params: $params) {
			id
			orderID
			status
			updatedAt
		}
	}
`
