import React from "react"

// import styled from "styled-components"

// Components
import CalculatorLayout from "../../layouts/calculator/calculator.layout"
import Spinner from "../spinner/spinner.component"

const Fallback: React.FC = () => {
	return (
		<CalculatorLayout>
			<Spinner center />
		</CalculatorLayout>
	)
}

export default Fallback

// const Container = styled.div`
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	width: 100%;
// 	height: 100%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	background-color: #fff;
// 	/* background-color: rgba(0, 0, 0, 0.5); */
// `
