// Utils
import React, { Suspense, lazy, useEffect } from "react";
import theme from "./styles/theme.styles";
import { motion, Variants, Transition } from "framer-motion";

// Layouts
import CalculatorLayout from "./layouts/calculator/calculator.layout";

// Components
import { Switch, Route } from "wouter";
import Fallback from "./components/fallback/fallback.component";
import { GlobalStyles } from "./styles/global.styles";
import NotificationModal from "./components/notifications/notifications-modal/notifications-modal.component";

// Hooks
import { useNotificationsContext } from "./context/notification/notification.context";
import { useLocalStorage } from "usehooks-ts";
import { useLocation } from "wouter";

// Providers
import { ThemeProvider } from "styled-components";
import ConfigProvider from "./context/config/config.context";
const HeatPumpProvider = lazy(
  () => import("./context/heat-pump/heat-pump.context")
);
const ElectricalInstallationsProvider = lazy(
  () =>
    import(
      "./context/electrical-installations/electrical-installations.context"
    )
);
const UnderfloorHeatingProvider = lazy(
  () => import("./context/underfloor-heating/underfloor-heating.context")
);
const PlumbingAndWasteFormProvider = lazy(
  () => import("./context/plumbing-and-waste/plumbing-and-waste.context")
);
const BuildingFormProvider = lazy(
  () => import("./context/building/building-form.context")
);
const BoilerProvider = lazy(() => import("./context/boiler/boiler.context"));
const CustomerProvider = lazy(
  () => import("./context/customer/customer.context")
);
const SolarPanelsProvider = lazy(
  () => import("./context/solar-panels/solar-panels.context")
);

// Pages
const HomePage = lazy(() => import("./pages/home/home.page"));
const BuildingPage = lazy(() => import("./pages/building/building.page"));
const SummaryPage = lazy(() => import("./pages/summary/summary.page"));
const CustomerPage = lazy(() => import("./pages/customer/customer.page"));

// Calculators pages
const BoilerPage = lazy(() => import("./pages/calculators/boiler/boiler.page"));
const ElectricalInstallationsPage = lazy(
  () =>
    import(
      "./pages/calculators/electrical-installations/electrical-installations.page"
    )
);
const HeatPumpPage = lazy(
  () => import("./pages/calculators/heat-pump/heat-pump.page")
);
const PlumbingAndWastePage = lazy(
  () => import("./pages/calculators/plumbing-and-waste/plumbing-and-waste.page")
);
const UnderfloorHeatingPage = lazy(
  () => import("./pages/calculators/underfloor-heating/underfloor-heating.page")
);
const SolarPanelsPage = lazy(
  () => import("./pages/calculators/solar-panels/solar-panels.page")
);
const FinishPage = lazy(() => import("./pages/finish/finish.page"));

const App: React.FC = () => {
  const [, setLocation] = useLocation();
  const { notifications } = useNotificationsContext();
  const [version, setVersion] = useLocalStorage("version", "1.0.0");

  const variants: Variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const transition: Transition = {
    duration: 0.7,
  };

  useEffect(() => {
    console.count("Check version");
    console.log({
      version,
      envVersion: process.env.REACT_APP_VERSION,
    });
    if (typeof process.env.REACT_APP_VERSION !== "string") return;
    if (version === process.env.REACT_APP_VERSION) return;
    console.count("App");
    // remove everything from local storage
    localStorage.clear();
    setVersion(process.env.REACT_APP_VERSION);
    setLocation("/");
  }, [version, setVersion, setLocation]);

  return (
    <React.Fragment>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <ConfigProvider>
          <Suspense fallback={<Fallback />}>
            <Switch>
              <Route path="/">
                {() => (
                  <motion.div
                    variants={variants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    transition={transition}
                  >
                    <BuildingFormProvider>
                      <PlumbingAndWasteFormProvider>
                        <ElectricalInstallationsProvider>
                          <UnderfloorHeatingProvider>
                            <BoilerProvider>
                              <HeatPumpProvider>
                                <HomePage />
                              </HeatPumpProvider>
                            </BoilerProvider>
                          </UnderfloorHeatingProvider>
                        </ElectricalInstallationsProvider>
                      </PlumbingAndWasteFormProvider>
                    </BuildingFormProvider>
                  </motion.div>
                )}
              </Route>

              <Route path="/hotovo">
                {() => (
                  <motion.div
                    variants={variants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    transition={transition}
                  >
                    <FinishPage />
                  </motion.div>
                )}
              </Route>

              <CalculatorLayout>
                <Route path="/udaje-o-stavbe">
                  {() => (
                    <motion.div
                      key={"udaje-o-stavbe"}
                      variants={variants}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      transition={transition}
                    >
                      <BuildingFormProvider>
                        <BuildingPage />
                      </BuildingFormProvider>
                    </motion.div>
                  )}
                </Route>

                {/* Calculators */}
                <Route path="/vodoinstalacia-a-odpady">
                  {() => (
                    <motion.div
                      key={"vodoinstalacia-a-odpady"}
                      variants={variants}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      transition={transition}
                    >
                      <PlumbingAndWasteFormProvider>
                        <PlumbingAndWastePage />
                      </PlumbingAndWasteFormProvider>
                    </motion.div>
                  )}
                </Route>
                <Route path="/elektroinstalacia">
                  {() => (
                    <motion.div
                      key={"elektroinstalacia"}
                      variants={variants}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      transition={transition}
                    >
                      <ElectricalInstallationsProvider>
                        <ElectricalInstallationsPage />
                      </ElectricalInstallationsProvider>
                    </motion.div>
                  )}
                </Route>
                <Route path="/kotol">
                  {() => (
                    <motion.div
                      key={"kotol"}
                      variants={variants}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      transition={transition}
                    >
                      <BoilerProvider>
                        <BoilerPage />
                      </BoilerProvider>
                    </motion.div>
                  )}
                </Route>
                <Route path="/tepelne-cerpadlo">
                  {() => (
                    <motion.div
                      key={"tepelne-cerpadlo"}
                      variants={variants}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      transition={transition}
                    >
                      <HeatPumpProvider>
                        <HeatPumpPage />
                      </HeatPumpProvider>
                    </motion.div>
                  )}
                </Route>
                <Route path="/podlahove-kurenie">
                  {() => (
                    <motion.div
                      key={"podlahove-kurenie"}
                      variants={variants}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      transition={transition}
                    >
                      <UnderfloorHeatingProvider>
                        <UnderfloorHeatingPage />
                      </UnderfloorHeatingProvider>
                    </motion.div>
                  )}
                </Route>
                <Route path="/solarne-panely">
                  {() => (
                    <motion.div
                      key={"solarne-panely"}
                      variants={variants}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      transition={transition}
                    >
                      <SolarPanelsProvider>
                        <SolarPanelsPage />
                      </SolarPanelsProvider>
                    </motion.div>
                  )}
                </Route>
                <Route path="/zhrnutie">
                  {() => (
                    <motion.div
                      key={"zhrnutie"}
                      variants={variants}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      transition={transition}
                    >
                      <BuildingFormProvider>
                        <PlumbingAndWasteFormProvider>
                          <ElectricalInstallationsProvider>
                            <UnderfloorHeatingProvider>
                              <BoilerProvider>
                                <HeatPumpProvider>
                                  <SolarPanelsProvider>
                                    <SummaryPage />
                                  </SolarPanelsProvider>
                                </HeatPumpProvider>
                              </BoilerProvider>
                            </UnderfloorHeatingProvider>
                          </ElectricalInstallationsProvider>
                        </PlumbingAndWasteFormProvider>
                      </BuildingFormProvider>
                    </motion.div>
                  )}
                </Route>
                <Route path="/zakaznik">
                  {() => (
                    <motion.div
                      key={"zakaznik"}
                      variants={variants}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      transition={transition}
                    >
                      <CustomerProvider>
                        <CustomerPage />
                      </CustomerProvider>
                    </motion.div>
                  )}
                </Route>
              </CalculatorLayout>
            </Switch>
          </Suspense>
          <NotificationModal notifications={notifications} />
        </ConfigProvider>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
