// Utils
import { useContext, createContext, useCallback, useEffect } from "react"

// Hooks
import { useLocation } from "wouter"
import {
	// useEffectOnce,
	useBoolean,
	useLocalStorage,
} from "usehooks-ts"
import { useMutation } from "@apollo/client"

// Mutations
import { PUSH_PRICE_OFFER } from "./config.mutations"

// Utils
// import { mongoObjectId } from "../../utils/utils"

// Types
import {
	IConfigProviderProps,
	IConfigContext,
	IConfig,
	IPushPriceOfferData,
	IPushPriceOfferVars,
	IPushPriceOfferHandleProps,
	ICategory,
} from "./config.types"

const ConfigContext = createContext<IConfigContext>({} as IConfigContext)

export const useConfigContext = () => useContext(ConfigContext)

const ConfigProvider: React.FC<IConfigProviderProps> = ({ children }) => {
	const [location] = useLocation()

	const { value: canNavigate, setValue: setNavigate } = useBoolean(false)

	const [
		config,
		setConfig,
		// removeStorageUserData
	] = useLocalStorage<IConfig | null>("config", null)

	const setCalculators = (calculators: IConfig["calculators"]) => {
		setConfig((prevValue) => ({
			...prevValue,
			calculators,
		}))
	}

	const updateConfig = useCallback(
		(
			newConfig: IConfig | ((newConfig: IConfig | null) => void),
			canNavigate?: boolean
		) => {
			setConfig((prevValue) => {
				const newValue =
					newConfig instanceof Function ? newConfig(prevValue) : newConfig

				return {
					...prevValue,
					...newValue,
				}
			})

			if (typeof canNavigate === "boolean") {
				setNavigate(canNavigate)
			}
		},
		[setConfig, setNavigate]
	)

	const [pushPriceOffer, { loading, error }] =
		useMutation<IPushPriceOfferData, IPushPriceOfferVars>(PUSH_PRICE_OFFER)

	const pushPriceOfferHandler = (obj: IPushPriceOfferHandleProps) => {
		pushPriceOffer({
			variables: obj.vars,
			onCompleted: obj.onCompleted,
		})
	}

	const categories: ICategory[] = [
		{
			name: "Voda",
			icon: {
				src: "/assets/category-icons/voda.svg",
				alt: "voda",
			},
			items: [
				{
					order: 1,
					label: "Vodoinštalácia a odpady",
					name: "plumbingAndWaste",
					id: "plumbing-and-waste",
					slug: "/vodoinstalacia-a-odpady",
					icon: {
						src: "/assets/breadcumb-icons/plumbing-and-waste.svg",
						alt: "plumbing-and-waste",
					},
				},
				// {
				// 	label: "Solárne panely",
				// 	name: "solarPanel",
				// 	id: "solar-panels",
				// 	slug: "/solarne-panely",
				// 	icon: {
				// 		src: "/assets/breadcumb-icons/solar-panels.svg",
				// 		alt: "solar-panels",
				// 	},
				// },
			],
		},
		{
			name: "Teplo",
			icon: {
				src: "/assets/category-icons/teplo.svg",
				alt: "teplo",
			},
			items: [
				{
					order: 2,
					label: "Podlahové kúrenie",
					name: "underfloorHeating",
					id: "underfloor-heating",
					slug: "/podlahove-kurenie",
					icon: {
						src: "/assets/breadcumb-icons/underfloor-heating.svg",
						alt: "underfloor-heating",
					},
				},
				// {
				// 	label: "Klimatizácie",
				// 	name: "airConditioning",
				// 	id: "air-conditioning",
				// 	slug: "/klimatizacie",
				// },
				{
					order: 4,
					label: "Tepelné čerpadlo",
					name: "heatPump",
					id: "heat-pump",
					slug: "/tepelne-cerpadlo",
					icon: {
						src: "/assets/breadcumb-icons/heat-pump.svg",
						alt: "heat pump",
					},
				},
				{
					order: 5,
					label: "Kotol",
					name: "boiler",
					id: "boiler",
					slug: "/kotol",
					icon: {
						src: "/assets/breadcumb-icons/boiler.svg",
						alt: "boiler",
					},
				},
			],
		},
		{
			name: "Elektrina",
			icon: {
				src: "/assets/category-icons/elektrina.svg",
				alt: "elektrina",
			},
			items: [
				{
					order: 3,
					label: "Elektroinštalácie",
					name: "electricalInstallations",
					id: "electrical-installations",
					slug: "/elektroinstalacia",
					icon: {
						src: "/assets/breadcumb-icons/electrical-installations.svg",
						alt: "electrical-installations",
					},
				},
			],
		},
	]

	useEffect(() => {
		if (canNavigate) return
		if (!config?.calculators) return

		const hasIndex = config?.calculators.findIndex(
			(item) => item.slug === location
		)
		if (hasIndex === -1 && location !== "/zhrnutie") return
		setNavigate(true)
	}, [config?.calculators, location, setNavigate, canNavigate])

	return (
		<ConfigContext.Provider
			value={{
				config,
				categories,
				loading: loading,
				error: error,
				canNavigate,
				setCalculators,
				updateConfig,
				pushPriceOfferHandler,
			}}
		>
			{children}
		</ConfigContext.Provider>
	)
}

export default ConfigProvider
