// Utils
import ReactDOM from "react-dom"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"

// Components
import NotificationItem from "../notification-item/notification-item.component"

// Hooks
// import { useMemo } from "react"

// Types
import { INotification } from "../../../context/notification/notification.types"

export interface IProps {
	notifications: INotification[]
	portalId?: string
}

const NotificationModal: React.FC<IProps> = ({ notifications, portalId }) => {
	const el: HTMLElement = document.getElementById(portalId || "portal")!

	// const reversedNotifications = notifications.reverse()

	return ReactDOM.createPortal(
		<ModalContainer>
			<AnimatePresence>
				{notifications
					.map((i, idx) => (
						<NotificationItem key={idx} action={i.action} text={i.text} />
					))
					.reverse()}
			</AnimatePresence>
		</ModalContainer>,
		el
	)
}

export default NotificationModal

const ModalContainer = styled(motion.div)`
	position: fixed;
	z-index: 999999;
	bottom: 2rem;
	left: 0;
	right: 0;
	/* border: 1px solid blue; */

	@media all and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		left: unset;
		top: unset;
		/* border: 1px solid red; */
		max-width: 40rem;
		width: 100%;
		bottom: 3rem;
		right: 3rem;
		/* padding: 1.5rem; */
	}
`
