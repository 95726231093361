// Utils
import styled from "styled-components"

// Components
import BreadcumbItem from "../breadcumb-item/breadcumb-item.component"
import BreadcumbBetweenItem from "../breadcumb-between-item/breadcumb-between-item.component"
import { Fragment } from "react"

// Hooks
import { useLocation } from "wouter"

// Types
import { ICalculator } from "../../types/calculator.types"
export interface IProps {
	items: ICalculator[]
	canNavigate: boolean
	hasBuildingFormCompleted: boolean
}

const Breadcumb: React.FC<IProps> = ({
	items,
	canNavigate,
	hasBuildingFormCompleted,
}) => {
	const [location, setLocation] = useLocation()

	return (
		<Fragment>
			<BreadcumbItem
				icon={{
					src: "/assets/breadcumb-icons/home.svg",
					alt: "home icon",
				}}
				label="Údaje o stavbe"
				isActive={location === "/udaje-o-stavbe"}
				isCompleted={hasBuildingFormCompleted}
				onClick={() => setLocation("/udaje-o-stavbe")}
				cursorPointer
			/>
			<BreadcumbBetweenItem />

			{items.map((item, index) => {
				return (
					<Wrapper key={index}>
						<BreadcumbItem
							icon={{
								src: item.icon.src,
								alt: item.icon.alt,
							}}
							label={item.label}
							isActive={location === item.slug}
							onClick={canNavigate ? () => setLocation(item.slug) : () => {}}
							cursorPointer={canNavigate}
							isCompleted={item.isCompleted}
						/>
						<BreadcumbBetweenItem />
					</Wrapper>
				)
			})}

			<BreadcumbItem
				icon={{
					src: "/assets/breadcumb-icons/summary.svg",
					alt: "summary icon",
				}}
				label="Zhrnutie"
				isActive={location === "/zhrnutie" || location === "/zakaznik"}
				onClick={canNavigate ? () => setLocation("/zhrnutie") : () => {}}
				cursorPointer={canNavigate}
			/>
		</Fragment>
	)
}

export default Breadcumb

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 1.1rem;
`
