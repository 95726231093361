// Utils
import styled from "styled-components"
import {
	motion,
	HTMLMotionProps,
	Variants,
	AnimatePresence,
} from "framer-motion"

// Components
import Image from "../image/image.component"

// Hooks
import { useTheme } from "styled-components"

// Types
export interface IProps extends HTMLMotionProps<"button"> {
	isActive?: boolean
	isCompleted?: boolean
	icon: {
		src: string
		alt: string
	}
	label: string
	cursorPointer?: boolean
}

const BreadcumbItem: React.FC<IProps> = ({
	icon,
	label,
	isActive,
	...props
}) => {
	const theme = useTheme()

	const ButtonWrapperVariants: Variants = {
		active: {
			backgroundColor: theme.breadcumb.activeBackgroundColor,
			width: "auto",
			transition: {
				duration: 0.4,
				ease: "linear",
			},
		},
		inactive: {
			backgroundColor: theme.breadcumb.backgroundColor,
			width: "4.9rem",
			transition: {
				duration: 0.4,
				ease: "linear",
			},
		},
	}

	const labelVariants: Variants = {
		active: {
			opacity: 1,
			transition: {
				duration: 0.4,
				ease: "linear",
			},
		},
		inactive: {
			opacity: 0,
			transition: {
				duration: 0.4,
				ease: "linear",
			},
		},
	}

	return (
		<AnimatePresence>
			<ButtonWrapper
				variants={ButtonWrapperVariants}
				initial="inactive"
				animate={isActive ? "active" : "inactive"}
				exit="inactive"
				{...props}
			>
				<IconFigure>
					<Image src={icon.src} alt={icon.alt} width={20} height={20} />
				</IconFigure>

				<Label
					variants={labelVariants}
					initial="inactive"
					animate={isActive ? "active" : "inactive"}
				>
					{label}
				</Label>
			</ButtonWrapper>
		</AnimatePresence>
	)
}

export default BreadcumbItem

interface IButtonWrapperProps {
	isActive?: boolean
	isCompleted?: boolean
	cursorPointer?: boolean
}

const ButtonWrapper = styled(motion.button)<IButtonWrapperProps>`
	cursor: ${({ cursorPointer }) => (cursorPointer ? "pointer" : "default")};
	display: flex;
	align-items: center;
	gap: 1rem;
	border-radius: 999rem;
	overflow: hidden;
	/* padding: 0.6rem 1.4rem; */
	border: ${({ theme, isCompleted }) =>
		isCompleted
			? `1px solid ${theme.color.green}`
			: `1px solid ${theme.breadcumb.backgroundColor}`};
	background-color: ${({ theme }) => theme.breadcumb.backgroundColor};
`

const IconFigure = styled.figure`
	/* display: flex;
    align-items: center;
    justify-content: center; */
	padding: 0.6rem 0.3rem 0.6rem 1.4rem;
`

const Label = styled(motion.span)`
	display: block;
	font-size: 1.3rem;
	font-weight: 700;
	color: ${({ theme }) => theme.breadcumb.color};
	padding: 0.6rem 2rem 0.6rem 0;
	white-space: nowrap;
`
