// Utils

// Components
import Wrapper from "../../components/wrapper/wrapper.component"
import Container from "../../components/container/container.component"
import FlexContainer from "../../components/flex-container/flex-container.component"
import Logo from "../../components/logo/logo.component"
import Breadcumb from "../../components/breadcumb/breadcumb.component"

// Hooks
import { useConfigContext } from "../../context/config/config.context"

// Types
export interface IProps {
	children: React.ReactNode | React.ReactNode[]
}

const CalculatorLayout: React.FC<IProps> = ({ children }) => {
	const { config, canNavigate } = useConfigContext()
	return (
		<Wrapper>
			<div>
				<FlexContainer
					bottom="2rem"
					// wrap="nowrap"
					alignItems="center"
					gap="1.1rem"
					justifyContent="start"
				>
					<Logo />
					<Breadcumb
						hasBuildingFormCompleted={!!config?.hasBuildingFormCompleted}
						canNavigate={!!canNavigate}
						items={config?.calculators || []}
					/>
				</FlexContainer>

				<Container>{children}</Container>
			</div>
		</Wrapper>
	)
}

export default CalculatorLayout
