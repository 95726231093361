// Utils
import styled from "styled-components"

// Components
import Image from "../image/image.component"

const BreadcumbBetweenItem: React.FC = () => {
	return (
		<Wrapper>
			<Image
				src="/assets/breadcumb-icons/between-item.svg"
				alt="breadcumb between item"
				width={22}
				height={22}
			/>
		</Wrapper>
	)
}

export default BreadcumbBetweenItem

const Wrapper = styled.figure`
	display: flex;
	justify-content: center;
	align-items: center;
`
