import styled from "styled-components"

export interface IProps {
	center?: boolean
}

const Spinner: React.FC<IProps> = ({ center }) => {
	return <SpinnerContainer center={center} />
}

export default Spinner

export const SpinnerContainer = styled.div<IProps>`
	border: 5px solid transparent;
	border-top: 5px solid ${({ theme }) => theme.color.primary};
	border-radius: 50%;
	width: 38px;
	height: 38px;
	animation: spin 800ms linear infinite;
	margin: ${({ center }) => (center ? "0 auto" : "0")};

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`
