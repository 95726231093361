import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";

// Hooks
import { useDebounce } from "usehooks-ts";

// Types
import {
  INotificationContext,
  INotification,
  INotificationActionType,
  INotificationProvider,
} from "./notification.types";

const NotificationsContext = createContext({} as INotificationContext);

export const useNotificationsContext = () => useContext(NotificationsContext);

const NotificationsProvider: React.FC<INotificationProvider> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const pushNotification = useCallback(
    (action: INotificationActionType, text?: string) => {
      setNotifications((prevValue) => [
        ...prevValue,
        {
          action,
          text,
        },
      ]);
    },
    []
  );

  const debouncedNotifications = useDebounce<typeof notifications>(
    notifications,
    5000
  );

  useEffect(() => {
    if (!debouncedNotifications.length) return;
    const arr = debouncedNotifications;
    arr.shift();
    setNotifications([...arr]);
  }, [debouncedNotifications, setNotifications]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        pushNotification: () => {},
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
