import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  *, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    scroll-behavior: smooth;
    scroll-snap-type: y proximity;
    font-size: 62.5%;
  }
  body {
    line-height: 1.5;
    font-size: 1.6rem;
    font-family: 'Raleway', sans-serif;
    line-height: 1.5;
    background-color: #F8F8F8;
  }

  h1 {
  }

  p {
  }

  a {
    text-decoration: none;
  }
  ul, ol {
    list-style: none;
  }
  input, button {
    border: none;
    outline: none;
    font-family: inherit;
    &:focus {
      outline: none;
    }
  }

  input {
    font-size: 1.8rem;
  }
`
