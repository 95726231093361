// Utils
import styled from "styled-components";

// Components
import Image from "../image/image.component";
import { Link } from "wouter";

const Logo: React.FC = () => {
  return (
    <Link href="/">
      <a>
        <LogoFigure>
          <Image
            width={150}
            height={80}
            src="/assets/logo.svg"
            alt="renovahouse logo"
          />
        </LogoFigure>
      </a>
    </Link>
  );
};

export default Logo;

const LogoFigure = styled.figure`
  cursor: pointer;
  margin-right: 1.9rem;
`;
