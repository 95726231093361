// Utils
import styled from "styled-components"

export interface IProps {
	children: React.ReactNode | React.ReactNode[]
}

const Container: React.FC<IProps> = ({ children }) => {
	return <ContainerStyles>{children}</ContainerStyles>
}

export default Container

const ContainerStyles = styled.div`
	background-color: ${({ theme }) => theme.background.primary};
	padding: 2.8rem 2rem;
	border-radius: 0.7rem;
	box-shadow: 0 0.5rem 2rem 1rem rgba(0, 0, 0, 0.05);
`
